import { atom } from "recoil";

let token = ""

if(localStorage.getItem("token")) {
    token = localStorage.getItem("token")
}

const tokenAtom = atom({
    key:"tokenAtom" ,
    default: token
})

export default tokenAtom