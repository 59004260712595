import { toast } from "sonner";
import axiosInstance from "../../api/apiAxios";
import { redirect } from "react-router-dom";

export default async function updateLight(e ,id) {
    e.preventDefault()
    let fd = new FormData(e.target)
    let res = await axiosInstance.post(`/update-loupesAndLight/${id}` , fd)

    if(res.response?.data.errors) {
        let result = Object.values(res.response?.data.errors)
        result.forEach((mess)=>{
            toast.error(mess)
        })
    } else {
        toast.success("updated Successfully")
        return true
    }
}