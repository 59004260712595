import { Button, Container, Input, Stack, TextField } from "@mui/material";
import { useRecoilState } from "recoil";
import loginAtom from "../../atoms /loginAtom";
import LoginUser from "../../features/login";
import tokenAtom from "../../atoms /tokenAtom";

export default function LoginPage() {
    
    const [_ , setLogin] = useRecoilState(loginAtom)
    const [ , setToken] = useRecoilState(tokenAtom)

    async function handleFunction(e){
        e.preventDefault()
        let formData = new FormData(e.target)
        // fetch("https://xpedent.net/admin/public/api/login" , {
        //     method : "POST",
        //     body : JSON.stringify({
        //         email:"admin@admin",
        //         password : "123456789"
        //     })
        // })
        let token = await LoginUser(formData ,setLogin, setToken)
        
    
    }


    return (
        <Container className="loginPage h-screen flex items-center">
            <form class="max-w-sm mx-auto w-full" onSubmit={handleFunction}>
            <div class="mb-5">
                <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> email</label>
                <input name="email" type="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
            </div>
            <div class="mb-5">
                <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> password</label>
                <input name="password" type="password" id="password" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
            </div>
           
            <button type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
            </form>
        </Container>
    )
}