import { AiOutlinePlus } from "react-icons/ai"; 
import DashboardLayout from "../../layout/dashboardLayout";
import TestBox from "../../components/testBox/testBox";
import { Link } from "react-router-dom";
import getTesti from "../../features/testi/getTesti";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";

export default function Testimonials () {


    const {data ,isLoading} = useQuery({ queryKey: ['TestXpedent'], queryFn: getTesti })

 

    return (
        <DashboardLayout>
            <div className="flex justify-between items-center">
                <h3 className="text-xl font-bold ">
                   Testimonials
                </h3>
       
                    <Link to={"/Testimonials/add"} type="button" class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm  px-4 py-3 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 items-center gap-4 overflow-hidden transition-all w-[42px] flex hover:w-[210px]">
                        <span>
                            <AiOutlinePlus />
                        </span>
                        <span className="flex-1 whitespace-nowrap">Add New Testimonials</span>
                    </Link>
               
            </div>
            <div className="grid grid-cols-3 max-lg:grid-cols-2 max-sm:grid-cols-1 gap-3 ">
                {
                    data?.map((testi)=>{
                        return <TestBox testi={testi}/>

                    })
                }
          
            </div>
        </DashboardLayout>
    )
}