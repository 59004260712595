import { atom } from "recoil";

let isLogin = false 

if(localStorage.getItem("token")) {
    isLogin = true
}


const loginAtom = atom({
    key:"loginAtom" ,
    default: isLogin
})

export default loginAtom