import {  useNavigate } from "react-router-dom"
import DashboardLayout from "../../../layout/dashboardLayout"
import { useState } from "react"
import createErgo from "../../../features/ergo/createErgo";
import createContact from "../../../features/contact/createContact";
import 'react-international-phone/style.css';
import {
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import {
  defaultCountries,
  FlagImage,
  parseCountry,
  usePhoneInput,
} from 'react-international-phone';
import { useMutation, useQueryClient } from "@tanstack/react-query";


export default function AddContact() {


     const queryClient = useQueryClient()


  // Mutations
  const mutation = useMutation({
    mutationFn: handleAction,
    onSuccess: () => {
      // Invalidate and refetch

      queryClient.invalidateQueries({ queryKey: ['ContactsXpedent'] })
    },
  })
const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      defaultCountry: 'us',
      value:"",
      countries: defaultCountries,
      onChange: (data) => {
      
      },
    });
    const [isLoading , setIsLoading] = useState(false)

    const navigate = useNavigate();

    async function handleAction (e) {
        let res = await createContact(e)
    
        if(res == true) {
             navigate("/contact")
        }
    }

    return (
        <DashboardLayout>
            <form class="max-w-sm mx-auto" onSubmit={mutation.mutate}>
                 <div class="mb-5">
                    <label for="Name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Name</label>
                    <input name="name" type="text" id="Name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter name" required />
                </div>
                 <div class="mb-5">
                    <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> email</label>
                    <input name="email" type="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter email" required />
                </div>
                
              
            <label for="phone" class="block text-sm font-medium text-gray-900 dark:text-white">Phone Number</label>
               
              <TextField
                    id="phone"
                    name="phoneCountry"
                    variant="filled"
                    label="Phone number"
                    color="primary"
                    placeholder="Phone number"
                    value={inputValue}
                    onChange={handlePhoneValueChange}
                    type="tel"
                    inputRef={inputRef}
                    InputProps={{
                        startAdornment: (
                        <InputAdornment
                            position="start"
                            style={{ marginRight: '2px', marginLeft: '-8px' }}
                        >
                            <Select
                            MenuProps={{
                                style: {
                                height: '300px',
                                width: '360px',
                                top: '10px',
                                left: '-34px',
                                },
                                transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                                },
                            }}
                            sx={{
                                width: 'max-content',
                                // Remove default outline (display only on focus)
                                fieldset: {
                                display: 'none',
                                },
                                '&.Mui-focused:has(div[aria-expanded="false"])': {
                                fieldset: {
                                    display: 'block',
                                },
                                },
                                // Update default spacing
                                '.MuiSelect-select': {
                                padding: '8px',
                                paddingRight: '24px !important',
                                
                                },
                                svg: {
                                right: 0,
                                },
                            }}
                            value={country.iso2}
                            onChange={(e) => setCountry(e.target.value)}
                            renderValue={(value) => (
                                <FlagImage iso2={value} style={{ display: 'flex' }} />
                            )}
                            >
                            {defaultCountries.map((c) => {
                                const country = parseCountry(c);
                                return (
                                <MenuItem key={country.iso2} value={country.iso2}>
                                    <FlagImage
                                    iso2={country.iso2}
                                    style={{ marginRight: '8px' }}
                                    />
                                    <Typography marginRight="8px">{country.name}</Typography>
                                    <Typography color="gray">+{country.dialCode}</Typography>
                                </MenuItem>
                                );
                            })}
                            </Select>
                        </InputAdornment>
                        ),
                    }}
                    sx={{
                        margin:"10px 0",
                        width:"100%",
                        
                    }}
                />
              

                 <div class="mb-5">
                    <label for="job" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> job</label>
                    <input name="job" type="text" id="job" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter job" required />
                </div>
                 <div class="mb-5">
                    <label for="note" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> note</label>
                    <input name="note" type="text" id="note" defaultValue={""} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter email"  />
                </div>
      
                
                <button disabled={mutation.isPending}  class=" w-full focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 mt-10">Save</button>

            </form>
        </DashboardLayout>

    )
}