import { Link } from "react-router-dom";
import DashboardLayout from "../../layout/dashboardLayout";
import { AiOutlinePlus } from "react-icons/ai";
import ErgonomicsCard from "../../components/Ergonomics/ErgonomicsCard";
import { useEffect, useState } from "react";
import getErgo from "../../features/ergo/getErgo";
import { useQuery } from "@tanstack/react-query";
import getLight from "../../features/light/getLight";
import LightsCard from "../../components/Lights/LightsCard";

export default function LightsPage() {


            const {data ,isLoading} = useQuery({ queryKey: ['LightsXpedent'], queryFn: getLight })



    return (
        <DashboardLayout>
            <div className="flex justify-between items-center">
                <h1 className="text-3xl mb-3 font-bold">Lights & Loups</h1>
                <Link to={"/Lights/add"} type="button" class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm  px-4 py-3 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 items-center gap-4 overflow-hidden transition-all w-[42px] flex hover:w-[210px]">
                    <span>
                        <AiOutlinePlus />
                    </span>
                    <span className="flex-1 whitespace-nowrap">Add New Lights & Loups</span>
                </Link>
            </div>
             <div className="grid grid-cols-2 max-lg:grid-cols-1 gap-3 ">
                {
                    data?.map((ergo)=>{
                        return(
                                <LightsCard ergo={ergo}/>
                         
                        )
                    })
                }
            </div>
        </DashboardLayout>
    )
} 