import {  useNavigate } from "react-router-dom"
import DashboardLayout from "../../../layout/dashboardLayout"
import { useState } from "react"
import createErgo from "../../../features/ergo/createErgo";
import createLight from "../../../features/light/createLight";


export default function AddLights() {

    const [isLoading , setIsLoading] = useState(false)

    const navigate = useNavigate();

    async function handleAction (e) {
        setIsLoading(true)
        let res = await createLight(e)
        setIsLoading(false)
        if(res == true) {
             navigate("/Lights")
        }
    }

    return (
        <DashboardLayout>
            <form class="max-w-sm mx-auto" onSubmit={handleAction}>
                 <div class="mb-5">
                    <label for="title" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> title</label>
                    <input name="title" type="text" id="title" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter name" required />
                </div>
                <label for="description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> description</label>
                <textarea name="description" id="description" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="description ..."></textarea>
                <div>
                    <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white" for="image">image</label>
                    <input class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="user_avatar_help" id="image" type="file" name="image" />
                    <div class="mt-1 text-sm text-gray-500 dark:text-gray-300" id="image">  image </div>
                </div>
                
                <button  class=" w-full focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 mt-10">Save</button>

            </form>
        </DashboardLayout>

    )
}