import { useState } from "react";
import { AiOutlineDelete } from "react-icons/ai"; 
import { BiEditAlt } from "react-icons/bi"; 
import { Link } from "react-router-dom";
import deleteProfession from "../../features/profession/deleteProfession";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import loading from '../../assests/loading.gif'
export default function ProfessionCard({proff}) {

    const [openModal , setOpenModal] = useState(false)


    const queryClient = useQueryClient()


    // Mutations
    const mutation = useMutation({
      mutationFn: deleteProfession,
      onSuccess: () => {
        // Invalidate and refetch
        setOpenModal(false)
        queryClient.invalidateQueries({ queryKey: ['ProXpedent'] })
      },
    })
    return (

        <>

        <div id="popup-modal" tabindex="-1" class={`${openModal ? "flex" : "hidden"} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full `}>
            <div class="relative p-4 w-full max-w-md max-h-full">
                <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <button type="button" onClick={()=>{
                        setOpenModal(false)
                    }} class="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal">
                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span class="sr-only">Close modal</span>
                    </button>
                    <div class="p-4 md:p-5 text-center">
                        <svg class="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>
                        <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete this proffmonils?</h3>
                        <button disabled={mutation.isPending} onClick={()=>{
                            mutation.mutate(proff.id , setOpenModal)
                        }}
                         data-modal-hide="popup-modal" type="button" class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                            {
                                mutation.isPending ?
                                <img src={loading} alt="" /> 
                                :
                                " Yes, I'm sure"
                            }
                        </button>
                        <button data-modal-hide="popup-modal" type="button" onClick={()=>{
                        setOpenModal(false)
                    }} class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">No, cancel</button>
                    </div>
                </div>
            </div>
        </div>

        <div style={{backgroundImage:`url(${proff.image})`}} class="w-full h-[320px] bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 flex items-end bg-cover">
            
            <div class="flex flex-col items-center  bg-[#000000a9] justify-end w-full">
                {/* <img class="mb-3 shadow-lg object-cover object-center" src={proff.image} alt="Bonnie image"/> */}
                <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">{proff.title}</h5>
                
                <div class="flex mt-4 md:mt-6">
                    <button type="button" onClick={()=>{
                        setOpenModal(true)
                    }} class="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">
                    <AiOutlineDelete />
                    </button>
                <Link to={`/Profession/edit/${proff.id}`} class="focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:focus:ring-yellow-900">
                    <BiEditAlt />
                </Link>

                    </div>
            </div>
        </div>
        </>
    )
}