import Navbar from "../components/navbar/Navbar";
import Sidebar from "../components/Sidebar";
import { Container } from "@mui/material";
export default function DashboardLayout ({children}) {
    return (

        <>
            <Navbar/>
            <Sidebar/>
            <div class="p-4 sm:ml-64 mt-16 ">
      
          {children}
         </div>
             
            
          </>
        
    )
}