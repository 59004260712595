import { toast } from "sonner";
import axiosInstance from "../api/apiAxios";

export default async function LoginUser(formData, setLogin , setToken) {
    try {
        let res =  await axiosInstance.post("/login" , formData)  
     
        if(res.response?.data.error) {   
           
            toast.error(res.response.data.error)
        } else {
            toast.success('Login Success');
            setLogin(true)  
            localStorage.setItem("token" , res.data.token)
            setToken(res.data.token)
        }
    } catch (err) {
 
    }
}