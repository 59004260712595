// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Navbar from "./components/navbar/Navbar";
import './App.css'
import HomePage from "./Pages/Home/Home";


import Profession from "./Pages/Profession/Profession";
import LoginPage from "./Pages/Login/Login";
import { PrivateRoute, PublicRoute } from "./PrivateRoutes/PrivateRoutes";
import { useRecoilValue } from "recoil";
import loginAtom from "./atoms /loginAtom";
import Testimonials from "./Pages/Testimonials/Testimonials";
import AddTestimonials from "./Pages/Testimonials/add/addTestimonials";
import { Toaster } from "sonner";
import EditTestimonials from "./Pages/Testimonials/edit/editTestimonials";
import AddProfession from "./Pages/Profession/addProfession/addProfession";
import EditProfession from "./Pages/Profession/edit/editProfession";
import Ergonomics from "./Pages/ergonomics/Ergonomics";
import AddErgonomics from "./Pages/ergonomics/addErgonomics/addErgonomics";
import EditErgonomics from "./Pages/ergonomics/edit/editErgonomics";
import Contact from "./Pages/contact/Contact";
import AddContact from "./Pages/contact/add/addContact";
import EditContact from "./Pages/contact/edit/editContact";
import NotFound from "./components/404/notFound";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import LightsPage from "./Pages/Lights/Lights";
import AddLights from "./Pages/Lights/addLight/addLight";
import EditLights from "./Pages/Lights/edit/editLights";

const queryClient = new QueryClient()
function App() {


  const isLogin = useRecoilValue(loginAtom)

  return (
    <>
    <QueryClientProvider client={queryClient}>
      {/* <ReactQueryDevtools initialIsOpen={true} /> */}

      <Toaster position="top-center" richColors/>
        <Routes>
          <Route element={<PublicRoute isLogin={isLogin}/>}>
            <Route path="/Profession" element={<Profession/>}/>
            <Route path="/Profession/add" element={<AddProfession/>}/>
            <Route path="/Profession/edit/:ProffId" element={<EditProfession/>}/>
            <Route path="/Testimonials" element={<Testimonials/>}/>
            <Route path="/" element={<Testimonials/>}/>
            <Route path="/Testimonials/add" element={<AddTestimonials/>}/>
            <Route path="/Testimonials/edit/:TestiId" element={<EditTestimonials/>}/>
            <Route path="/ergonomics" element={<Ergonomics/>}/>
            <Route path="/ergonomics/add" element={<AddErgonomics/>}/>
            <Route path="/ergonomics/edit/:ErgoId" element={<EditErgonomics/>}/>
            <Route path="/Lights" element={<LightsPage/>}/>
            <Route path="/Lights/add" element={<AddLights/>}/>
            <Route path="/Lights/edit/:LightId" element={<EditLights/>}/>
            <Route path="/contact" element={<Contact/>}/>
            <Route path="/contact/add" element={<AddContact/>}/>
            <Route path="/contact/edit/:ContactId" element={<EditContact/>}/>
          </Route>
          <Route element={<PrivateRoute isLogin={isLogin}/>}>
            <Route path="/Login" element={<LoginPage/>}/>
          </Route>
          <Route element={<NotFound/>} path="/notFound"/>
          <Route element={<NotFound/>} path="/*"/>
        </Routes>
    </QueryClientProvider>


    </>
  );
}

export default App;
