import { redirect, useNavigate, useParams } from "react-router-dom"
import axiosInstance from "../../../api/apiAxios"
import CreateTest from "../../../features/testi/createTesti"
import DashboardLayout from "../../../layout/dashboardLayout"
import { useEffect, useState } from "react"
import testiDetails from "../../../features/testi/getTestiuDetails"
import editTest from "../../../features/testi/editTesti"

export default function EditTestimonials() {

    const [isLoading , setIsLoading] = useState(false)
    const [data, setData] = useState({})
    const {TestiId} = useParams()
    const navigate = useNavigate();

    useEffect(()=>{
        const getData = async ()=>{
            let res = await testiDetails(TestiId)
           setData(res)
        }
        getData()
    },[TestiId])

    async function handleAction (e) {
        setIsLoading(true)
        let res = await editTest(e , TestiId)
        setIsLoading(false)
        if(res == true) {
             navigate("/Testimonials")
        }
    }

    return (
        <DashboardLayout>
            <form class="max-w-sm mx-auto" onSubmit={handleAction}>
                 <div class="mb-5">
                    <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> name</label>
                    <input defaultValue={data.name} name="name" type="text" id="name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter name" required />
                </div>
                 <div class="mb-5">
                    <label for="product" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Product</label>
                    <input defaultValue={data.product} name="product" type="text" id="product" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter Product" required />
                </div>
                 <div class="mb-5">
                    <label for="rate" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> rate</label>
                    <input defaultValue={data.rate} min={1} max={5} name="rate" type="number" id="rate" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter rate"  required />
                </div>
                <label for="review_content" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">review_content</label>
                <textarea defaultValue={data.review_content} name="review_content" id="review_content" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Leave a review ..."></textarea>
                <div>
                    <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white" for="user_avatar">profile picture</label>
                    <input class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="user_avatar_help" id="user_avatar" type="file" name="image" />
                    <div class="mt-1 text-sm text-gray-500 dark:text-gray-300" id="user_avatar_help"> profile picture </div>

                </div>
                <img src={data.image} alt="" className="w-full h-[340px] object-cover mt-3"/>
                <button  class=" w-full focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 mt-10">Edit</button>

            </form>
        </DashboardLayout>

    )
}