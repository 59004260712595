import { useRecoilState } from "recoil"
import loginAtom from "../atoms /loginAtom"
import { useLayoutEffect } from "react"
import axiosInstance from "./apiAxios"
import tokenAtom from "../atoms /tokenAtom"
import { toast } from "sonner"
import { Navigate } from "react-router-dom"

export default function IntereptorProvider ({children}) {

    const [isLoggin , setIsloggin] = useRecoilState(loginAtom)
    const [token , setToken] = useRecoilState(tokenAtom)

    useLayoutEffect(()=>{
        const requestInterceptor = axiosInstance.interceptors.request.use((config)=>{
            config.headers.Authorization = `Bearer ${token}`
            return config
        })

        return ()=>{
            axiosInstance.interceptors.request.eject(requestInterceptor)
        }

    },[token])


    useLayoutEffect(()=>{
        const responesInter =axiosInstance.interceptors.response.use(config => config , (error)=>{
            if(error.status == 401) {
                toast.error("Please Login Again")
                setIsloggin(false)
                setToken("")
            } else if (error.status == 404) {
                // window.location.replace("/notFound")
            }
            return error
        })
         return ()=>{
            axiosInstance.interceptors.response.eject(responesInter)
        }
        },[])

    return children
}