import { redirect, useNavigate, useParams } from "react-router-dom"
import axiosInstance from "../../../api/apiAxios"
import CreateTest from "../../../features/testi/createTesti"
import DashboardLayout from "../../../layout/dashboardLayout"
import { useEffect, useState } from "react"
import editTest from "../../../features/testi/editTesti"
import ProffDetails from "../../../features/profession/getProfessionDetails"
import editProff from "../../../features/profession/editProfessoion"

export default function EditProfession() {

    const [isLoading , setIsLoading] = useState(false)
    const [data, setData] = useState({})
    const {ProffId} = useParams()
    const navigate = useNavigate();
  
    useEffect(()=>{
        const getData = async ()=>{
            let res = await ProffDetails(ProffId)
           setData(res)
        }
        getData()
    },[ProffId])

    async function handleAction (e) {
        setIsLoading(true)
        let res = await editProff(e , ProffId)
        setIsLoading(false)
        if(res == true) {
             navigate("/Profession")
        }
    }

    return (
           <DashboardLayout>
            <form class="max-w-sm mx-auto" onSubmit={handleAction}>
                 <div class="mb-5">
                    <label  for="title" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> title</label>
                    <input defaultValue={data.title} name="title" type="text" id="title" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter name" required />
                </div>
                {
                    data?.details?.map((detail)=>{
                        return(
                            <>
                                <div class="mb-5">
                                 <label for="header_title" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> header title</label>
                                 <input defaultValue={detail.header_title} name="header_title" type="text" id="header_title" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter rate"  required />
                                </div>
                                <label for="header_desc" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">header description</label>
                                <textarea defaultValue={detail.header_desc} name="header_desc" id="header_desc" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Leave a review ..."></textarea>
                                <div>
                                    <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white" for="header_image">header image</label>
                                    <input class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="user_avatar_help" id="header_image" type="file" name="header_image" />
                                    <div class="mt-1 text-sm text-gray-500 dark:text-gray-300" id="header_image"> header image </div>
                                    <img src={detail.header_image} alt="" />
                                </div>
                                <div class="mb-5">
                                    <label for="body_title" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> body title</label>
                                    <input defaultValue={detail.body_title} name="body_title" type="text" id="body_title" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter rate"  required />
                                </div>
                                <label for="body_content" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">body content</label>
                                <textarea  defaultValue={detail.header_desc} name="body_content" id="body_content" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Leave a review ..."></textarea>
                    
                            </>
                        )
                    })
                }
              <div>
                    <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white" for="image">card image</label>
                    <input class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="user_avatar_help" id="image" type="file" name="image" />
                    <div class="mt-1 text-sm text-gray-500 dark:text-gray-300" id="image"> image card </div>
                    <img src={data.image} alt="" />
                </div>
                <button  class=" w-full focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 mt-10">Save</button>

            </form>
        </DashboardLayout>
    )
}