import notFoundImg from '../../assests/3737258.png'
import DashboardLayout from '../../layout/dashboardLayout'

export default function NotFound() {
    return (
        <DashboardLayout>
            <div className='flex justify-center h-[calc(100vh-55px)]'>
                <img src={notFoundImg} alt="" className='w-full h-full object-contain'/>
            </div>
        </DashboardLayout>
    )
}